class DfSelectedListDisplay extends HTMLElement {
  static get observedAttributes() {
    return ['items']
  }

  attributeChangedCallback(name, _, newValue) {
    if (name === 'items') {
      const items = JSON.parse(newValue)
      this._items = items
      const itemsWrapper = this.shadowRoot.querySelector(
        '.df-selected-list-display'
      )
      itemsWrapper.replaceChildren()

      for (let item of items) {
        const displayer = document.createElement('div')
        displayer.classList.add('item-display')

        const text = document.createElement('p')
        text.textContent = item
        text.classList.add('display-text')

        const removeButton = document.createElement('button')
        removeButton.textContent = 'X'
        removeButton.classList.add('remove-button')

        removeButton.addEventListener('click', () => {
          this.changeSelection(item)
        })

        displayer.appendChild(text)
        displayer.appendChild(removeButton)
        itemsWrapper.appendChild(displayer)
      }
    }
  }

  changeSelection(item) {
    this.dispatchEvent(
      new CustomEvent('change', {
        detail: item,
        bubbles: true,
        composed: true,
      })
    )
  }

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    this._listenersAdded = false
    this._items = []

    this.shadowRoot.innerHTML = `
      <style scoped>
        .df-selected-list-display {
          display: flex;
          gap: 8px;
          flex-wrap: wrap;
        }
        .item-display {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          padding: 4px 8px;
          border-radius: 4px;
          border: 1px solid #E6E9E6;
          background-color: #FFF;
        }
        .display-text {
          margin: 0px;
          color: #1A2B46;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
        .remove-button {
          border: none;
          background-color: #fff;
          color: #5B6459;
          font-size: 14px;
          font-weight: 700;
          cursor: pointer;
        }
      </style>
      <div class="df-selected-list-display"></div>
    `
  }
}

customElements.define('df-selected-list-display', DfSelectedListDisplay)
