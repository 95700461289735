<template>
  <div class="df-flex-xl df-flex-col df-flex-m-m new-sowing-content">
    <div class="df-flex-sm flex-nowrap content__warning">
      <font-awesome-icon color="#F2CD12" icon="exclamation-triangle" />
      <span>
        {{ $t('DiseasesControl.execute_program_modal.new_sowing_warning') }}
      </span>
    </div>
    <section>
      <v-form ref="form" class="df-flex-xl df-flex-col">
        <df-select
          v-model="selectedFields"
          clearable
          multiple
          search-bar
          :items="fields"
          :label="$t('DiseasesControl.execute_program_modal.fields_to_execute')"
          :placeholder="
            $t('DiseasesControl.execute_program_modal.select_fields')
          "
          :search-placeholder="
            $t('DiseasesControl.execute_program_modal.search_field')
          "
          :rules="[required]"
        />
        <div class="df-flex-sm df-flex-col">
          <p>
            {{ $t('DiseasesControl.execute_program_modal.selected_fields') }}
          </p>
          <df-selected-list-display
            v-if="selectedFieldNames.length"
            :items="selectedFieldNames"
            @change="removeSelectedField"
          />
          <p v-else class="field__warning">-----</p>
        </div>
        <div class="df-flex-sm df-flex-col">
          <div class="df-flex-l">
            <div class="df-col-6 df-col-12-t df-col-12-m">
              <df-date-picker
                v-model="sowingDate"
                event-color="#F2CD12"
                :exception-dates="notRecommendedDates"
                :hint="
                  $t('DiseasesControl.execute_program_modal.sowing_period')
                "
                :label="$t('DiseasesControl.execute_program_modal.sowing_date')"
                :max="currentSeasonCrop.end_period"
                :min="currentSeasonCrop.start_period"
                :placeholder="
                  $t('DiseasesControl.execute_program_modal.select_date')
                "
                :rules="[required]"
              />
            </div>
            <div class="df-col-6 df-col-12-t df-col-12-m">
              <df-input
                v-model="sownArea"
                suffix="ha"
                type="number"
                :label="$t('DiseasesControl.execute_program_modal.sown_area')"
                :placeholder="
                  $t('DiseasesControl.execute_program_modal.insert_area')
                "
                :rules="[required]"
                @change="setSelectedProgramCost"
              />
            </div>
          </div>
          <div
            v-if="isAreaExceeded"
            class="df-flex-sm align-center area__warning"
          >
            <font-awesome-icon color="#F23528" icon="exclamation-circle" />
            <p>
              {{
                $t('DiseasesControl.execute_program_modal.sowing_area_warning')
              }}
            </p>
          </div>
        </div>
        <div class="df-flex-l">
          <div class="df-col-6 df-col-12-t df-col-12-m">
            <df-date-picker
              v-model="emergenceSowingDate"
              :hint="
                $t('DiseasesControl.execute_program_modal.intend_sowing_period')
              "
              :label="
                $t('DiseasesControl.execute_program_modal.emergence_date')
              "
              :max="currentSeasonCrop.end_period"
              :min="currentSeasonCrop.start_period"
              :placeholder="
                $t('DiseasesControl.execute_program_modal.select_date')
              "
              :rules="[required]"
            />
          </div>
          <div class="df-col-6 df-col-12-t df-col-12-m">
            <df-input
              v-model="cycle"
              type="number"
              :label="
                $t('DiseasesControl.execute_program_modal.estimated_cycle')
              "
              :placeholder="
                $t('DiseasesControl.execute_program_modal.type_estimated_cycle')
              "
              :suffix="$t('DiseasesControl.execute_program_modal.days')"
              :rules="[required]"
            />
          </div>
        </div>
      </v-form>
    </section>
    <sowing-complementary-data ref="sowingComplementaryData" />
  </div>
</template>

<script>
import DfDatePicker from '@/lib/components/DatePicker/DfDatePicker.vue'
import DfInput from '@/lib/components/Input/DfInput.vue'
import DfSelect from '@/lib/components/Select/DfSelect.vue'
import SowingComplementaryData from '@/modules/sowingsList/components/modal/SowingComplementaryData.vue'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import { EventBus } from '@/utils/eventBus'
import { mapGetters } from 'vuex'
import { required } from '@/utils/formRules'
import { SOYBEAN_ID, WHEAT_ID } from '@/utils/crops'
import '@/lib/components/Select/DfSelectedListDisplay.js'

export default {
  name: 'NewSowingContent',

  components: {
    DfDatePicker,
    DfInput,
    DfSelect,
    SowingComplementaryData,
  },

  props: {
    cropId: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      cycle: null,
      emergenceSowingDate: '',
      fields: [],
      required,
      selectedFields: [],
      sowingDate: '',
      sownArea: null,
    }
  },

  created() {
    this.fetchFormattedFields()
  },

  computed: {
    ...mapGetters('farms', ['isFarmFromUSA']),
    ...mapGetters('seasonsCrops', ['currentSeasonCrop']),
    isAreaExceeded() {
      return this.sownArea - this.fieldTotalArea >= 0
    },
    fieldTotalArea() {
      // CM: validação a ser feita depois
      return 100
    },
    notRecommendedDates() {
      if (this.cropId) {
        const isSoybean = this.cropId == SOYBEAN_ID
        const isWheat = this.cropId == WHEAT_ID
        if (isSoybean && this.isFarmFromUSA) return []
        else if (isSoybean && !this.isFarmFromUSA) return []
        else if (isWheat && this.isFarmFromUSA) return []
        else if (isWheat && !this.isFarmFromUSA) return []
        else return []
      }
      return []
    },
    selectedFieldNames() {
      const names = this.selectedFields.map((field) => field.name)
      if (names.length) return JSON.stringify(names)
      else return ''
    },
  },

  methods: {
    async fetchFormattedFields() {
      try {
        const fieldsParams = { farm_id: this.currentFarmId, is_active: true }
        const { data } = await sowingsPlanningService.getFields(fieldsParams)
        this.fields = data.map((field) => {
          return {
            name: field.name,
            value: String(field.id),
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    removeSelectedField(item) {
      const index = this.selectedFields.findIndex(
        (field) => field.name === item.detail
      )
      this.selectedFields.splice(index, 1)
    },
    setSelectedProgramCost(value) {
      EventBus.$emit('selectedProgramArea', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.new-sowing-content {
  .content__warning {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #fff2b4;
    background-color: #fffdf5;

    span {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .field__warning {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .area__warning {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #e5c2c0;
    background-color: #ffe1df;
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
