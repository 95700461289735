class ButtonGroup extends HTMLElement {
  static get observedAttributes() {
    return ['labels']
  }

  attributeChangedCallback(name, _, newValue) {
    if (name === 'labels') {
      const labels = JSON.parse(newValue)
      const firstButton = this.shadowRoot.querySelector('#first-button')
      const secondButton = this.shadowRoot.querySelector('#second-button')

      firstButton.textContent = labels[0]
      secondButton.textContent = labels[1]

      this.changeSelectedButton(firstButton)
    }
  }

  connectedCallback() {
    if (this._listenersAdded) return
    this._listenersAdded = true

    const firstButton = this.shadowRoot.querySelector('#first-button')
    const secondButton = this.shadowRoot.querySelector('#second-button')

    firstButton.addEventListener('click', () =>
      this.changeSelectedButton(firstButton)
    )
    secondButton.addEventListener('click', () =>
      this.changeSelectedButton(secondButton)
    )
  }

  changeSelectedButton(button) {
    this.shadowRoot
      .querySelectorAll('button')
      .forEach((btn) => btn.classList.remove('selected'))

    button.classList.add('selected')

    this.dispatchEvent(
      new CustomEvent('change', {
        detail: button.textContent,
        bubbles: true,
        composed: true,
      })
    )
  }

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    this._listenersAdded = false
    this.shadowRoot.innerHTML = `
      <style lang="scss" scoped>
        .button-group {
          display: flex;
          gap: 4px;
          padding: 4px;
          border-radius: 24px;
          background: #F8F9F8
        }
        button {
          flex-grow: 1;
          padding: 12px 24px;
          border: none;
          border-radius: 24px;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          color: #5B6459;
          background-color: #F8F9F8;
          cursor: pointer;

          @media (max-width: 767px) {
            padding: 12px;
          }
        }
        .selected {
          color: #FFF;
          background-color: #39AF49;
        }
      </style>
      <div id="group" class="button-group">
        <button id="first-button">Teste</button>
        <button id="second-button">Teste2</button>
      </div>
    `
  }
}

customElements.define('button-group', ButtonGroup)
