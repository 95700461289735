<template>
  <div
    class="df-flex-l df-flex-col selected-program"
    :style="{ maxHeight: maxHeight, minHeight: maxHeight }"
  >
    <h2>{{ $t('DiseasesControl.execute_program_modal.chosen_program') }}</h2>
    <div class="program__card">
      <section class="df-flex-l df-flex-col program__infos">
        <div class="df-flex-l align-center flex-nowrap">
          <div class="df-col-6">
            <div class="df-flex-xm df-flex-col">
              <p class="infos__name">{{ program.name }}</p>
              <h3>{{ $t(program.crop_name) }} | {{ program.variety_name }}</h3>
            </div>
          </div>
          <div class="df-col-1"></div>
          <div class="df-col-5">
            <div class="df-flex-xm align-center infos__score">
              <span>
                {{ $t('DiseasesControl.execute_program_modal.program_score') }}:
              </span>
              <font-awesome-icon color="#F2CD12" icon="star" />
              <p>{{ program.rate.toFixed(1) }}</p>
            </div>
          </div>
        </div>
        <div class="df-flex-l infos__details">
          <div class="df-flex-sm df-flex-col">
            <h4>
              {{ $t('DiseasesControl.execute_program_modal.sowing_date') }}
            </h4>
            <p>{{ programDate }}</p>
          </div>
          <div class="df-flex-sm df-flex-col">
            <h4>
              {{ $t('DiseasesControl.execute_program_modal.program_cost') }}
            </h4>
            <p>
              {{ $currency(programCost) }} /
              {{ $unitMeasures['area'][$currentLanguage()] }}
            </p>
          </div>
          <div class="df-flex-sm df-flex-col">
            <h4>
              {{ $t('DiseasesControl.execute_program_modal.total_sprayings') }}
            </h4>
            <p>{{ program.sprayings.length }}</p>
          </div>
        </div>
      </section>
      <v-divider />
      <section class="df-flex-xl df-flex-col program__sprayings">
        <div
          v-for="(spraying, index) in program.sprayings"
          :key="spraying.id"
          class="df-flex-l df-flex-col spraying"
        >
          <div class="df-flex-m align-center">
            <h4>
              {{ formatOrdinal(spraying.spraying_order) }}
              {{ $t('DiseasesControl.execute_program_modal.spraying') }}
            </h4>
            <div class="df-flex-xm align-center">
              <font-awesome-icon color="#5B6459" icon="calendar" />
              <p>{{ spraying.ideal_date.formatDate() }}</p>
            </div>
            <div class="df-flex-xm align-center">
              <font-awesome-icon color="#5B6459" icon="dollar-sign" />
              <p>{{ $currency(sprayingCost(spraying)) }} / ha</p>
            </div>
          </div>
          <div class="df-flex-l">
            <div class="df-flex-sm df-flex-col">
              <h5>
                {{ $t('DiseasesControl.execute_program_modal.products') }}
              </h5>
              <span v-for="product in spraying.products" :key="product.id">
                {{ product.name }}
              </span>
            </div>
            <div class="df-flex-sm df-flex-col">
              <h5>{{ $t('DiseasesControl.execute_program_modal.dose') }}</h5>
              <span v-for="product in spraying.products" :key="product.id">
                {{ product.dose_per_hectare }} L
              </span>
            </div>
            <div class="df-flex-sm df-flex-col">
              <h5>{{ $t('DiseasesControl.execute_program_modal.price') }}</h5>
              <span v-for="product in spraying.products" :key="product.id">
                {{ $currency(product.price_per_liter) }} / L
              </span>
            </div>
            <div class="df-flex-sm df-flex-col">
              <h5>
                {{
                  $t('DiseasesControl.execute_program_modal.cost_per_hectare')
                }}
              </h5>
              <span v-for="product in spraying.products" :key="product.id">
                {{
                  $currency(product.dose_per_hectare * product.price_per_liter)
                }}
              </span>
            </div>
          </div>
          <div class="df-flex-none align-center">
            <h5 class="flex-grow-1">
              {{
                $t('DiseasesControl.execute_program_modal.spraying_efficacy')
              }}
            </h5>
            <v-btn
              v-if="expanded[index]"
              class="flex-grow-0"
              icon
              small
              @click="toggleExpandedSprayingItem(index)"
            >
              <font-awesome-icon color="#5B6459" icon="chevron-up" />
            </v-btn>
            <v-btn
              v-else
              class="flex-grow-0"
              icon
              small
              @click="toggleExpandedSprayingItem(index)"
            >
              <font-awesome-icon color="#5B6459" icon="chevron-right" />
            </v-btn>
          </div>
          <efficacy-chart
            v-if="expanded[index]"
            colorful="true"
            :efficacies="efficacies(spraying.efficacies)"
          />
          <v-divider
            v-if="shouldShowDivider(index, program.sprayings.length)"
          />
        </div>
        <div class="df-flex-sm align-center spraying__total-cost">
          <h5>{{ $t('DiseasesControl.execute_program_modal.total_cost') }}</h5>
          <span>{{ totalCost }}</span>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import '@/components/Efficacies/EfficacyChart.js'
import { EventBus } from '@/utils/eventBus'
import { formatOrdinal } from '@/language'

export default {
  name: 'SelectedProgram',

  props: {
    maxHeight: {
      required: true,
      type: String,
    },
    program: {
      required: true,
      type: Object,
    },
    selectedSowings: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    return {
      expanded: [],
      formatOrdinal,
      sowingArea: null,
    }
  },

  created() {
    EventBus.$on('selectedProgramArea', this.setSowingArea)
    this.expanded = this.program.sprayings.map(() => false)
  },

  computed: {
    programCost() {
      return this.program.sprayings.reduce((acc, spraying) => {
        acc += spraying.products.reduce((acc2, product) => {
          acc2 += product.dose_per_hectare * product.price_per_liter
          return acc2
        }, 0)
        return acc
      }, 0)
    },
    programDate() {
      return (
        (this.program.date && this.program.date.formatDate()) ||
        this.program.estimated_sowing_date.formatDate()
      )
    },
    totalCost() {
      if (!this.selectedSowings.length && !this.sowingArea) {
        return this.$t('DiseasesControl.execute_program_modal.select_sowing')
      }
      const pricePerHectare = this.program.sprayings.reduce((acc, spraying) => {
        acc += spraying.products.reduce((acc2, product) => {
          acc2 += product.dose_per_hectare * product.price_per_liter
          return acc2
        }, 0)
        return acc
      }, 0)
      if (this.selectedSowings.length) {
        const costSum = this.selectedSowings.reduce((acc, sowing) => {
          acc += pricePerHectare * sowing.area
          return acc
        }, 0)
        return this.$currency(costSum)
      } else {
        return this.$currency(pricePerHectare * this.sowingArea)
      }
    },
  },

  methods: {
    efficacies(efficacies) {
      let translatedEfficacies = [...efficacies]
      translatedEfficacies = translatedEfficacies.map((efficacy) => {
        return {
          disease_name: this.$t(`diseases.${efficacy.disease_name}`),
          efficacy: efficacy.efficacy.toFixed(2),
        }
      })
      return JSON.stringify(translatedEfficacies)
    },
    setSowingArea(value) {
      this.sowingArea = value
    },
    shouldShowDivider(index, sprayingsLength) {
      return index !== sprayingsLength - 1
    },
    sprayingCost(spraying) {
      return spraying.products.reduce((acc, product) => {
        acc += product.dose_per_hectare * product.price_per_liter
        return acc
      }, 0)
    },
    toggleExpandedSprayingItem(index) {
      this.$set(this.expanded, index, !this.expanded[index])
    },
  },

  beforeDestroy() {
    EventBus.$off('selectedProgramArea', this.setSowingArea)
  },
}
</script>

<style lang="scss" scoped>
.selected-program {
  height: 100%;
  padding: 24px;
  background-color: #f8f9f8;
  overflow-y: auto;

  @include d(m) {
    padding: 24px 12px;
    overflow-y: none;
  }

  h2 {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .program__card {
    border-radius: 8px;
    border: 1px solid #e6e9e6;
    background: #fff;

    .program__infos {
      padding: 16px 24px;

      @include d(m) {
        padding: 12px;
      }
      .infos__name {
        color: #5b6459;
        font-family: 'Rubik';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
      h3 {
        color: #1a2b46;
        font-family: 'Rubik';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .infos__score {
        span {
          color: #5b6459;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
        p {
          color: #1a2b46;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }
      .infos__details {
        h4 {
          color: #1a2b46;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
        p {
          color: #5b6459;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
        span {
          color: #5b6459;
          font-family: 'Rubik';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
    .program__sprayings {
      padding: 16px 24px;

      @include d(m) {
        padding: 12px;
      }
      .spraying {
        h4 {
          color: #1a2b46;
          font-family: 'Rubik';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
        p {
          color: #5b6459;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
        h5 {
          color: #1a2b46;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
        span {
          color: #5b6459;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
      .spraying__total-cost {
        padding: 12px 16px;
        border-radius: 4px;
        background-color: #f8f9f8;
        justify-content: end;

        @include d(m) {
          justify-content: start;
        }
        h5 {
          color: #1a2b46;
          font-family: 'Rubik';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
        span {
          color: #5b6459;
          font-family: 'Rubik';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
