<template>
  <div v-if="loadingFieldSowingList" class="df-flex-xl df-flex-col">
    <v-skeleton-loader type="image" height="88px" width="100%" />
    <div class="df-flex-l df-flex-col">
      <v-skeleton-loader type="image" height="16px" width="100%" />
      <v-skeleton-loader type="image" height="70px" width="100%" />
      <div class="df-flex-sm list__wrapper">
        <v-skeleton-loader
          v-for="(_, index) in fieldSowingsList.length || 3"
          :key="index"
          type="image"
          height="58px"
          width="100%"
        />
      </div>
    </div>
  </div>
  <div v-else-if="hasExecutedSowing" class="df-flex-xl df-flex-col">
    <div class="df-flex-sm flex-nowrap choose-sowing__information">
      <font-awesome-icon color="#4A76BC" icon="info" />
      <span
        v-html="$t('DiseasesControl.execute_program_modal.choose_sowing_info')"
      >
      </span>
    </div>
    <div class="df-flex-l df-flex-col">
      <h4>
        {{
          $t('DiseasesControl.execute_program_modal.select_sowing_in_season')
        }}
      </h4>
      <div class="df-flex-sm flex-nowrap choose-sowing__warning">
        <font-awesome-icon color="#F2CD12" icon="exclamation-triangle" />
        <span>
          {{
            $t('DiseasesControl.execute_program_modal.choose_sowing_warning')
          }}
        </span>
      </div>
      <v-expansion-panels
        v-model="expandedPanels"
        accordion
        flat
        multiple
        class="df-flex-sm df-flex-col"
      >
        <program-field-list
          v-for="fieldSowing in fieldSowingsList"
          :key="fieldSowing.field_id"
          :field-sowing="fieldSowing"
        />
      </v-expansion-panels>
    </div>
  </div>
  <div v-else class="df-flex-sm df-flex-col align-center sowings__empty-state">
    <font-awesome-icon color="#5B6459" icon="seedling" />
    <h2>
      {{ $t('DiseasesControl.execute_program_modal.without_executed_sowing') }}
    </h2>
    <p>
      {{ $t('DiseasesControl.execute_program_modal.select_button') }}
    </p>
  </div>
</template>

<script>
import ProgramFieldList from '@/modules/diseasesControl/components/modal/ProgramFieldList.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ChooseSowingContent',

  components: {
    ProgramFieldList,
  },

  props: {
    fieldSowingsList: {
      required: true,
      type: Array,
    },
    loadingFieldSowingList: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      expandedPanels: [0],
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    hasExecutedSowing() {
      return !this.loadingFieldSowingList && this.fieldSowingsList.length
    },
  },
}
</script>

<style lang="scss" scoped>
.list__wrapper {
  width: 100%;
}
.choose-sowing__information {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f8f9f8;
  background-color: #f8f9f8;

  span {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
.choose-sowing__warning {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #fff2b4;
  background-color: #fffdf5;

  span {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
.sowings__empty-state {
  padding: 16px;
  border: 1px solid #aab2a9;
  border-radius: 4px;
  background-color: #f8f9f8;

  h2 {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  p {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }
}
h4 {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
</style>
