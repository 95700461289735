<template>
  <v-expansion-panel class="program-field-list">
    <v-expansion-panel-header class="panel__header">
      <h3>{{ fieldSowing.field_name }}</h3>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="panel__content">
      <div
        v-for="sowing in fieldSowing.sowings"
        :key="sowing.id"
        class="df-col-6 df-flex-m df-col-12-m align-center flex-nowrap sowing-card"
      >
        <v-tooltip top content-class="card__tooltip">
          <template #activator="{ on }">
            <df-checkbox
              v-on="sowing.has_program ? on : null"
              :disabled="sowing.has_program"
              @change="handleCheckboxChange(sowing)"
            >
              <div class="df-flex-xm df-flex-col card__infos">
                <h4>
                  {{ $t('DiseasesControl.execute_program_modal.sowing_on') }}
                  {{ sowingDate(sowing) }}
                </h4>
                <p>
                  {{ $t(sowing.crop_message_key) }} | {{ sowing.variety_name }}
                </p>
              </div>
            </df-checkbox>
          </template>
          <span class="tooltip__text">
            {{ $t('DiseasesControl.execute_program_modal.has_program') }}
          </span>
        </v-tooltip>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import '@/lib/components/Checkbox/DfCheckbox.js'
import { EventBus } from '@/utils/eventBus'

export default {
  name: 'ProgramFieldList',

  props: {
    fieldSowing: {
      required: true,
      type: Object,
    },
  },

  methods: {
    handleCheckboxChange(sowing) {
      EventBus.$emit('selectedSowing', sowing)
    },
    sowingDate(sowing) {
      const currentDate = sowing.sown_at || sowing.to_sow_date
      const date = new Date(currentDate)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear()).slice(-2)

      return `${day}/${month}/${year}`
    },
  },
}
</script>

<style lang="scss" scoped>
.program-field-list {
  .panel__header {
    border-radius: 8px;
    border: 1px solid #e6e9e6 !important;
    padding: 16px 12px;

    h3 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .panel__content {
    padding: 12px !important;
    background: #f8f9f8;

    .sowing-card {
      padding: 12px 16px;
      border-radius: 4px;
      border: 1px solid #e6e9e6;
      background: #fff;

      .card__infos {
        height: fit-content;

        h4 {
          color: #1a2b46;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
        p {
          color: #5b6459;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
}
.card__tooltip {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e9e6;
  background-color: #fff;
  opacity: 1;
  max-width: 276px;
  text-align: center;

  .tooltip__text {
    color: #5b6459;
    text-align: center;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
::v-deep .v-expansion-panel-header--active {
  border-radius: 8px 8px 0px 0px !important;
  min-height: 60px;
}
::v-deep .v-expansion-panel-header__icon {
  margin: 0px;

  & .v-icon {
    color: #5b6459 !important;
  }
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
</style>
