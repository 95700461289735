<template>
  <df-modal
    persistent
    width="1140"
    without-padding
    :action-name="$t('DiseasesControl.execute_program_modal.execute_program')"
    :loading="loading"
    :scrollable="isScrollable"
    :style="modalStyle"
    :title="$t('DiseasesControl.execute_program_modal.execute_in_season')"
    @action-click="executeProgram"
    @close="closeExecuteProgramModal"
  >
    <template #card-content>
      <div ref="modalContent" class="df-flex-none">
        <div class="df-col-6 df-col-12-m">
          <program-sowing-infos
            ref="programSowingInfos"
            :max-height="maxHeight"
            :program="program"
          />
        </div>
        <div class="df-col-6 df-col-12-m">
          <selected-program
            :max-height="maxHeight"
            :program="program"
            :selected-sowings="selectedSowings"
          />
        </div>
      </div>
      <overwrite-sprayings-modal
        v-if="isOverwriteSprayingsModalOpened"
        :sowings="selectedSowings"
        :sprayings="selectedSprayings"
        @action-click="overwriteSprayings"
        @close="closeOverwriteSprayingsModal"
      />
    </template>
  </df-modal>
</template>

<script>
import DfModal from '@/lib/Modal/DfModal.vue'
import diseasesControlService from '@/modules/diseasesControl/services/diseasesControlService'
import LayoutMixin from '@/components/LayoutMixin.vue'
const OverwriteSprayingsModal = () =>
  import(
    '@/modules/diseasesControl/components/modal/OverwriteSprayingsModal.vue'
  )
import ProgramSowingInfos from '@/modules/diseasesControl/components/modal/ProgramSowingInfos.vue'
import SelectedProgram from '@/modules/diseasesControl/components/modal/SelectedProgram.vue'
import sowingsListService from '@/modules/sowingsList/services/sowingsListService.js'
import sowingViewService from '@/modules/sowingView/services/sowingViewService.js'
import { EventBus } from '@/utils/eventBus'
import { mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'ExecuteProgramModal',

  mixins: [LayoutMixin],

  components: {
    DfModal,
    OverwriteSprayingsModal,
    ProgramSowingInfos,
    SelectedProgram,
  },

  props: {
    program: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      isOverwriteSprayingsModalOpened: false,
      loading: false,
      maxHeight: '',
      modalHeight: '75vh',
      selectedSowings: [],
      selectedSprayings: [],
    }
  },

  inject: {
    requestPrograms: {
      from: 'requestPrograms',
    },
  },

  created() {
    EventBus.$on('selectedSowing', this.updateData)
  },

  mounted() {
    this.setModalHeight()
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('user', ['currentUser']),
    isScrollable() {
      return this.isDSTablet || this.isDSMobile
    },
    modalStyle() {
      return {
        height: this.modalHeight,
      }
    },
  },

  methods: {
    closeExecuteProgramModal() {
      this.$emit('close')
    },
    closeOverwriteSprayingsModal() {
      this.isOverwriteSprayingsModalOpened = false
    },
    async createSowings() {
      const createdSowingIds = []
      const newSowingContent =
        this.$refs.programSowingInfos.$refs.newSowingContent
      const newSowingInfos = {
        cycle: newSowingContent.cycle,
        emergenceSowingDate: newSowingContent.emergenceSowingDate,
        selectedFields: newSowingContent.selectedFields,
        sowingDate: newSowingContent.sowingDate,
        sownArea: newSowingContent.sownArea,
      }
      if (newSowingInfos.selectedFields.length) {
        this.loading = true
        const complementaryData = newSowingContent.$refs.sowingComplementaryData
        const complementaryInfos = {
          selectedSeedClass: complementaryData.selectedSeedClass,
          germinativePower: complementaryData.germinativePower,
          seedVigor: complementaryData.seedVigor,
          seederSystem: complementaryData.seederSystem,
          lineSpacing: complementaryData.lineSpacing,
          populationLines: complementaryData.populationLines,
          sownQuantity: complementaryData.sownQuantity,
          operatingSpeed: complementaryData.operatingSpeed,
          origin: complementaryData.origin,
          seedTreatment: complementaryData.seedTreatment,
          inoculant: complementaryData.inoculant,
          harvested: complementaryData.harvested,
          harvestedQuantity: complementaryData.harvestedQuantity,
        }
        let createdSowingId = null
        let sowingParams = {}
        const fieldIds = newSowingInfos.selectedFields.map((field) =>
          Number(field.value)
        )
        for (let fieldId of fieldIds) {
          sowingParams = {
            task: {
              type: 'sowing',
              estimated_start_date: newSowingInfos.sowingDate,
              estimated_completed_date: newSowingInfos.sowingDate,
              started_at: newSowingInfos.sowingDate,
              completed_at: newSowingInfos.sowingDate,
              account_id: this.currentUser.account.id,
              created_by: this.currentUser.id,
              is_planning: false,
              extra_info: {
                variety_name: this.program.variety_name,
                asset_owner_name: null,
                emergence_date: newSowingInfos.emergenceSowingDate,
                cycle: newSowingInfos.cycle,
                origin: complementaryInfos.origin,
                sown_quantity: complementaryInfos.sownQuantity,
                seeder_system_id: complementaryInfos.seederSystem.value,
                seed_class_id: complementaryInfos.selectedSeedClass.value,
                germinative_power: complementaryInfos.germinativePower,
                seed_vigor: complementaryInfos.seedVigor,
                line_spacing: complementaryInfos.lineSpacing,
                population_lines: complementaryInfos.populationLines,
                operating_speed: complementaryInfos.operatingSpeed,
                seed_treatment: complementaryInfos.seedTreatment,
                inoculant: complementaryInfos.inoculant,
                harvested: complementaryInfos.harvested,
                harvest_quantity: complementaryInfos.harvestedQuantity,
              },
            },
            crop_zone: {
              crop_id: this.program.crop_id,
              variety_id: this.program.variety_id,
              farm_id: this.currentFarmId,
              field_id: fieldId,
              area: newSowingInfos.sownArea,
            },
          }
          try {
            const { data } = await sowingsListService.createExecutedSowing(
              sowingParams
            )
            createdSowingId = data
            createdSowingIds.push(createdSowingId)
          } catch (error) {
            console.error(error)
          } finally {
            this.loading = false
          }
        }
      } else {
        this.$root.$emit(
          'notify',
          'warning',
          this.$t(
            'DiseasesControl.execute_program_modal.fields_to_execute_warning'
          )
        )
      }
      return createdSowingIds
    },
    async executeProgram() {
      if (this.$refs.programSowingInfos.chooseSowing) {
        if (this.selectedSowings.length) {
          this.selectedSprayings = await this.verifySowingsSprayings(
            this.selectedSowings
          )
          if (this.selectedSprayings.length)
            this.isOverwriteSprayingsModalOpened = true
          else this.executeProgramByChosenSowing()
        } else {
          this.$root.$emit(
            'notify',
            'warning',
            this.$t('DiseasesControl.execute_program_modal.sowings_to_execute')
          )
        }
      } else {
        const newSowingContent =
          this.$refs.programSowingInfos.$refs.newSowingContent
        if (!newSowingContent.$refs.form.validate()) return
        const createdSowingIds = await this.createSowings()
        if (createdSowingIds.length) {
          this.loading = true
          const params = {
            program_id: this.program.id,
            sowing_ids: createdSowingIds,
          }
          try {
            logEvent(events.diseasesControlModule.finish_add_sowing_in)
            await diseasesControlService.executeProgramInSeason(params)
            this.requestPrograms()
            this.closeExecuteProgramModal()
          } catch (error) {
            console.error(error)
          } finally {
            this.loading = false
          }
        }
      }
    },
    async executeProgramByChosenSowing() {
      this.loading = true
      const params = {
        program_id: this.program.id,
        sowing_ids: this.selectedSowings.map((sowing) => sowing.id),
      }
      try {
        logEvent(events.diseasesControlModule.finish_add_sowing_in)
        await diseasesControlService.executeProgramInSeason(params)
        this.requestPrograms()
        this.closeExecuteProgramModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async overwriteSprayings() {
      await this.executeProgramByChosenSowing()
      this.closeOverwriteSprayingsModal()
    },
    setModalHeight() {
      if (this.isDSMobile) {
        this.maxHeight = 'auto'
      } else if (this.isDSTablet || window.innerHeight < 900) {
        this.modalHeight = `90vh`
        const modalElement = this.$refs.modalContent
        if (modalElement) {
          const rect = modalElement.getBoundingClientRect()
          const viewportHeight = window.innerHeight
          let visibleHeight = 0

          if (rect.top >= 0 && rect.bottom <= viewportHeight) {
            visibleHeight = rect.height
          } else if (rect.top < 0 && rect.bottom > 0) {
            visibleHeight = rect.bottom
          } else if (rect.top > 0 && rect.bottom > viewportHeight) {
            visibleHeight = viewportHeight - rect.top
          } else {
            visibleHeight = 0
          }
          this.maxHeight = `${visibleHeight}px`
        }
      } else {
        this.maxHeight = '700px'
      }
    },
    updateData(sowing) {
      const index = this.selectedSowings.findIndex(
        (selectedSowing) => selectedSowing.id == sowing.id
      )
      if (index > -1) {
        this.selectedSowings.splice(index, 1)
      } else {
        this.selectedSowings.push(sowing)
      }
    },
    async verifySowingsSprayings(sowings) {
      const sowingIds = sowings.map((sowing) => sowing.id)
      const params = {
        sowing_ids: [sowingIds],
        spraying_status: 'all',
      }
      try {
        this.loading = true
        const { data } = await sowingViewService.getSowingSprayings(params)
        return data
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },

  beforeDestroy() {
    EventBus.$off('selectedSowing', this.updateData)
  },
}
</script>
